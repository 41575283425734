.header {
  position: relative;
  z-index: 10;
  background-color: #fff;
  margin-bottom: 32px;

  @media (min-width: 996px) {
    .header__body_mobile {
      display: none;
    }
  }
  @media (max-width: 996px) {
    background-color: #F5F5F5;
    margin-bottom: 20px;
    &.header-map {
      background-color: transparent;
      margin-bottom: 16px;

      .header__body_mobile {
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.20);
        padding: 8px 16px;

        .sidebar__mode {
          padding: 0 16px;
        }

        .sidebar__btn-mode {
          padding: 8px;
          flex: 1 1 50%;
          border-radius: 6px;
        }

        .sidebar__mode, .sidebar__list-mode {
          width: 100%;
        }

      }
    }
  }
  // .header__body
  &__body {
    display: flex;
    align-items: center;
    padding: 26px 0;

    @media (max-width: 996px) {
      display: none;
    }
  }

  // .header__body_mobile

  &__body_mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    @media (min-width: 996px) {
      .sidebar__mode {
        display: none;
      }
    }
  }

  @media (max-width: 996px) {
    .sidebar__mode {
      display: block;
    }
  }


  @media (min-width: 996px) {
    &__location-btn, &__search-btn {
      display: none;
    }
  }
  @media (max-width: 996px) {
    &__location-btn, &__search-btn {
      display: flex;
    }
    &__location-btn img, &__search-btn img {
      width: 24px;
      height: 24px;
    }
  }
  // .header__logos
  &__logos {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-right: 48px;
  }

  // .header__logo
  &__logo {
    position: relative;

    &:not(:last-child) {
      padding-right: 8px;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        height: 100%;
        background-color: #2C2C2C;
        width: 1px;
      }
    }

    img {
      max-width: 100%;
      object-fit: cover;
    }

  }

  // .header__nav
  &__nav {
  }

  // .header__list
  &__list {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
  }

  // .header__item
  &__item {
    font-size: 16px;
    line-height: 1.5;
    color: #6A6A6A;
    transition: all .3s ease 0s;

    &:hover {
      color: #2C2C2C;
    }

    .active {
      position: relative;
      color: #2C2C2C;

      &::after {
        content: '';
        position: absolute;
        bottom: -33px;
        left: 0;
        width: 100%;
        height: 2px;
        border-radius: 2px 2px 0px 0px;
        background: #19AB4A;
      }
    }
  }

}

