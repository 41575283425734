@font-face {
  font-family: SFProDisplay;
  font-display: swap;
  src: url("assets/fonts/SFProDisplay-Bold.woff2") format("woff2"), url("assets/fonts/SFProDisplay-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Rubrik New";
  font-display: swap;
  src: url("assets/fonts/new-rubrik.woff2") format("woff2"), url("assets/fonts/new-rubrik.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: SFProDisplay;
  font-display: swap;
  src: url("assets/fonts/SFProDisplay-Regular.woff2") format("woff2"), url("assets/fonts/SFProDisplay-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: SFProDisplay;
  font-display: swap;
  src: url("assets/fonts/SFProDisplay-Semibold.woff2") format("woff2"), url("assets/fonts/SFProDisplay-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
