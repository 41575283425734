@import url('https://fonts.googleapis.com/css?family=Roboto: regular,500,700,&display-swap');

*:focus, *:active {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 18px;
  background-color: #F5F5F5;

  &.noscroll {
    overflow: hidden;
  }
}

.wrapper {
  overflow: hidden;
}

.container {
  max-width: 1190px;
  padding: 0 30px;
  margin: 0 auto;
  @media (max-width: 996px) {
    padding: 0 16px;
  }
}

.home .container {
  @media (max-width: 996px) {
    padding: 0 0 0 16px;
  }
}


.title {
  font-family: 'Rubrik New';
  font-weight: 700;
  line-height: 1.3;
  font-size: 24px;
}


input[type=checkbox], input[type=radio] {
  display: none;
}


input[type="checkbox"]:checked + label::after,
input[type="radio"]:checked + label::after {
  position: absolute;
  background: url("assets/img/icons/selected.svg") 0 0 / cover no-repeat;
  content: "";
  width: 16px;
  height: 16px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  @media (max-width: 920px) {
    margin: initial;
    bottom: initial;
    right: 0px;
  }
}

@media (max-width: 996px) {
  .sidebar-map input[type=checkbox]:checked + label {
    color: #050000 !important;
    text-shadow: 0px 0px 1px #000000;
  }
}

.title-page {
  color: #2C2C2C;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 12px;
  @media (min-width: 996px) {
    display: none;
  }
}