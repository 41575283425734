.place-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  transition: all 0.5s ease 0s;
  opacity: 0;
  visibility: hidden;
  overflow-y: scroll;
  // pointer-events: none;
  z-index: 100;

  @media (max-width: 996px) {
    transition: all 0.5s ease 0s;
  }

  .place-modal__content {
    transform: translateX(-100%);

    @media (max-width: 996px) {
      transform: translate(0, 100%);
      border-radius: 12px;
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;

    .place-modal__content {
      @media (min-width: 996px) {
        transform: translateX(0%);
      }

      @media (max-width: 996px) {
        height: calc(100vh - 60px);
        transform: translate(0, 0);
      }
    }
  }

  // .place-modal__body
  &__body {
    min-height: 100%;
    display: flex;
    @media (max-width: 996px) {
      padding-top: 60px;
      display: flex;
      flex-direction: column;
      // justify-content: end;
      align-items: center;
    }
  }

  // .place-modal__close
  &__close {
    position: absolute;
    padding: 8px 8px 8px 7px;
    border-radius: 0px 6px 6px 0px;
    border-left: 1px solid #eaeaea;
    background-color: #fff;
    align-self: flex-start;
    top: 31px;
    right: -32px;

    @media (max-width: 996px) {
      background-color: #f5f5f5;
      align-self: flex-start;
      top: 238px;
      border-radius: 6px;
      border-left: none;
      padding: 8px;
      z-index: 2;
      right: 16px;
    }

    img {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  // .place-modal__content
  &__content {
    position: relative;

    background-color: #ffffff;
    border-radius: 0px 24px 0px 0px;
    transition: all 0.5s ease 0s;

    @media (min-width: 996px) {
      max-width: 389px;
    }
    @media (max-width: 996px) {
      height: 48vh;
      max-height: 100vh;
    }
  }

  // .place-modal__image
  &__image {
    position: relative;
    height: 202px;
    border-radius: 0px 24px 0px 0px;
    pointer-events: none;
    @media (max-width: 996px) {
      border-radius: 12px;
      height: 232px;
    }

    img {
      border-radius: 0px 24px 0px 0px;
      width: 100%;
      object-fit: cover;
      height: 100%;
      @media (max-width: 996px) {
        border-radius: 12px;
      }
    }
  }
  .place-modal__image .place-modal__cat-img {
    position: absolute;
    width: 80px;
    height: 80px;
    bottom: 20px;
    left: 16px;
    border-radius: 6px;
  }

  // .place-modal__info
  &__info {
    padding: 24px 32px;
    background-color: #fff;

    @media (max-width: 996px) {
      padding: 16px;
      border-radius: 16px 16px 0px 0px;
      margin-top: -16px;
      z-index: 1;
      position: relative;
    }
  }

  // .place-modal__category
  &__category {
    color: #6a6a6a;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px;
    line-height: 1.4;
    display: block;
    margin-bottom: 24px;

    @media (max-width: 996px) {
      display: none;
    }
  }

  // .place-modal__title
  &__title {
    color: #2c2c2c;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 2px;

    @media (max-width: 996px) {
      font-size: 24px;
      color: #10002b;
      margin-bottom: 14px;
      max-width: 90%;
    }
  }

  // .place-modal__cashback
  &__cashback {
    position: relative;
    border-radius: 8px;
    background: rgba(34, 190, 84, 0.2);
    color: #10002b;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px;
    line-height: 1.4;
    padding: 8px 16px 8px 56px;
    margin-bottom: 28px;

    @media (max-width: 996px) {
      margin-bottom: 24px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 16px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 24px;
      height: 24px;
      background: url("../../assets/img/icons/cashback.svg") 0 0 / cover no-repeat;
    }
  }

  // .place-modal__inner-attr
  &__inner-attr {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 24px;

    @media (max-width: 996px) {
      gap: 12px;
    }
  }

  // .place-modal__attr
  &__attr {
    color: #10002b;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px;
    line-height: 1.4;
    border-radius: 18px;
    background-color: #f5f5f5;
    max-width: max-content;
    padding: 4px 8px;
  }

  // .place-modal__short
  &__short {
    width: max-content;
    border-radius: 8px;
    background: #f5f5f5;
    padding: 4px 8px;
    color: #10002b;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42;
    margin-bottom: 12px;
  }
  // .place-modal__contacts
  &__contacts {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;
  }

  // .place-modal__contact
  &__contact {
    position: relative;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 18px;
    font-weight: 400;
    padding-left: 24px;
    line-height: 1.5;

    &:first-child,
    &:last-child {
      color: #22be54;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 16px;
      height: 16px;
      top: 6px;
      bottom: 0;
    }
  }

  &__contact_map {
    color: #22be54;
    &:first-child::before {
      background: url("../../assets/img/icons/map.svg") 0 0 / cover no-repeat;
    }
  }

  &__contact_time {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    &::before {
      top: 5px;
      bottom: initial;
      background: url("../../assets/img/icons/time.svg") 0 0 / cover no-repeat;
    }
  }
  &__contact_front {
    grid-template-columns: 1fr;
  }
  &__contact_phone {
    &::before {
      background: url("../../assets/img/icons/call.svg") 0 0 / cover no-repeat;
    }
  }

  &__contact_site_url {
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
    &::before {
      background: url("../../assets/img/icons/web.svg") 0 0 / cover no-repeat;
    }
  }

  // .place-modal__description
  &__description {
    color: #6a6a6a;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
    strong {
      font-weight: 700;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 7px;
      list-style: disc;
      padding-left: 27px;
      margin-bottom: 10px;
      li {
        letter-spacing: -0.408px;
      }
    }
  }

  &__referral-link {
    display: none;

    @media (max-width: 996px) {
      margin: 16px auto 10px;
      padding: 15px;
      display: block;
      width: 100%;
      max-width: 343px;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      background-color: #19ab4a;
      border-radius: 10px;
      cursor: pointer;
    }
  }
}
