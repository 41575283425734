.city-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  transition: all 0.5s ease 0s;
  opacity: 0;
  visibility: hidden;
  overflow-y: scroll;
  pointer-events: none;
  z-index: 100;

  .city-modal__content {
    @media (max-width: 996px) {
      transform: translate(0, 100%);
      border-radius: 12px;
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;

    .city-modal__content {
      @media (max-width: 996px) {
        transform: translate(0, 0);
      }
    }

    .city-modal__top {
      padding: 20px 16px 0;
    }

    .city-modal__list {
      padding: 20px 16px;
      background-color: #fff;
    }
  }

  &__body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
  }

  &__top {
    position: relative;
    margin-bottom: 20px;

    .place-modal__close {
      top: 20px;
      right: 16px;
    }
  }

  // city-modal__content
  &__content {
    background-color: #ffffff;
    border-radius: 0px 24px 0px 0px;
    transition: all 0.5s ease 0s;

    @media (max-width: 996px) {
      height: 83vh;
      width: 100%;
      max-height: 100vh;
    }
  }

  &__title {
    color: #2c2c2c;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.3;
  }

  .city {
    height: 48px;
    &:first-child {
      .category__title {
        margin-left: -7px;
      }
    }
    &__name {
      max-width: inherit;
      position: relative;
      color: #10002b;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 18px;
      line-height: 1.5;
      width: 100%;
      display: block;
      padding: 8px 0;
    }
  }

  input[type="checkbox"]:checked + label::after,
  input[type="radio"]:checked + label::after {
    width: 24px;
    height: 24px;
    right: 5px;
  }
}
