.sidebar {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  position: relative;
  z-index: 10;

  @media (min-width: 996px) {
    max-width: 260px;
  }

  @media (max-width: 996px) {
    width: 100vw;
    left: -16px;
    width: 100vw;

    &__mode {
      display: none;
    }
    &__reset {
      color: #2c2c2c;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 12px;
    }
    &__search-block {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: space-between;
    }
  }

  // .sidebar__mode
  &__mode {
    @media (min-width: 996px) {
      background-color: #fff;
      border-radius: 8px;
      padding: 10px;
      margin-bottom: 12px;
    }
  }

  &__list-mode {
    display: inline-flex;
    padding: 2px;
    border: 2px solid #f5f5f5;
    border-radius: 8px;
    background-color: #f5f5f5;

    @media (max-width: 996px) {
      border: 2px solid #e8e8e8;
      padding: 0;
      background-color: #e8e8e8;
    }
  }

  // .sidebar__btn-mode
  &__btn-mode {
    position: relative;
    border-radius: 6px;
    font-size: 14px;
    text-align: center;
    background-color: #f5f5f5;
    line-height: 1.4;

    padding: 6px 24px 6px 46px;
    border: 2px solid #f5f5f5;

    @media (max-width: 996px) {
      padding: 6px 40px;
      background-color: #e8e8e8;
      border: none;
    }

    &::after {
      position: absolute;
      left: 22px;
      content: "";
      background: url("../../assets/img/icons/list.svg") 0 0 / cover no-repeat;
      width: 18px;
      height: 18px;
      @media (max-width: 996px) {
        content: none;
      }
    }

    // .sidebar__btn-mode_active
    &_active {
      background-color: #fff;
    }
  }

  // .sidebar__btn-mode-map
  &__btn-mode-map {
    &::after {
      background: url("../../assets/img/icons/location.svg") 0 0 / cover no-repeat;
    }
  }

  // .sidebar__category
  &__category {
    @media (min-width: 996px) {
      border-radius: 12px;
      background: #fff;
      padding: 12px;
      min-height: 362px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  // .sidebar__search
  &__search {
    position: relative;
    border-radius: 6px;
    background-color: #f5f5f5;
    padding: 8px 8px 8px 34px;
    margin-bottom: 12px;
    width: 100%;

    [type="search"]::-webkit-search-cancel-button {
      -webkit-appearance: none;
      background-image: url("../../assets/img/icons/clear.svg");
      background-size: 20px 20px;
      height: 20px;
      width: 20px;
      cursor: pointer;
    }

    &::before {
      content: "";
      position: absolute;
      left: 6px;
      top: 0;
      bottom: 0;
      margin: auto;
      background: url("../../assets/img/icons/search.svg") 0 0 / cover no-repeat;
      width: 24px;
      height: 24px;
    }

    input {
      border: none;
      background: transparent;
      outline: none;
      font-size: 14px;
      line-height: 1.4;
      width: 100%;
      color: #2c2c2c;
      font-feature-settings: "clig" off, "liga" off;

      &::placeholder {
        color: #9a9a9a;
      }
    }

    @media (max-width: 996px) {
      display: none;
    }
  }

  // .sidebar__list
  &__list {
    overflow-y: scroll;
    max-height: 385px;
    
    &::-webkit-scrollbar {
      width: 3px;
      background-color: #f9f9fd;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #19ab4a;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background-color: #f9f9fd;
    }

    @media (max-width: 996px) {
      display: flex;
      gap: 8px;
      overflow-x: auto;
      width: 100%;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
  }

  // .sidebar__item
  // &__item {
  //   @media (max-width: 996px) {
  //     &:first-child {
  //       margin-left: 16px;
  //     }

  //     &:last-child {
  //       margin-right: 16px;
  //     }
  //   }
  // }
}

.category {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #ffffff;
  padding: 10px 6px;
  user-select: none;
  position: relative;
  &_search {
    @media (min-width: 996px) {
      font-size: 15px;
    }
  }

  @media (max-width: 996px) {
    flex-direction: column-reverse;
    padding: 8px;
    border-radius: 12px;
    flex: 0 0 112px;
    justify-content: flex-end;
    height: 76px;
    img {
      align-self: flex-end;
      margin-top: auto;
    }
  }
  // .category__title
  &__title {
    display: flex;
    width: 100%;
    text-align: left;
    line-height: 1.5;
    position: relative;
    cursor: pointer;

    strong {
      color: #2c2c2c;
      font-weight: 700;
    }

    @media (max-width: 996px) {
      font-size: 12px;
      line-height: 1.3;
      position: absolute;
      inset: 8px;
      max-width: 96px;
    }
  }
}

.search {
  padding: 40px 0px;

  &__title {
    color: #10002b;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 8px;
    text-align: center;
  }

  &__description {
    color: #6a6a6a;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px;
    line-height: 1.4;
  }
}

@media (max-width: 996px) {
  .sidebar-map {
    .sidebar__category {
      .category {
        flex-direction: row;
        gap: 6px;
        flex: 0 0 138px;
        //padding: 8px 12px;
        padding: 21px 12px;
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        height: initial;

        &__title {
          //white-space: nowrap;
          letter-spacing: -0.24px;
          font-size: 15px;
          font-family: "SFProDisplay";
          font-weight: 400;
          color: #10002b;
          max-width: 86px;

          &::after {
            content: none;
          }
        }

        img {
          margin-top: initial;
          align-self: center;
          width: 18px;
          height: 18px;
        }
      }
    }
    .sidebar__list {
      padding: 4px 0;
    }
  }
}
