.news {
  margin-bottom: 50px;

  .title {
    margin-bottom: 24px;
    font-size: 32px;
    font-family: 'Rubrik New';
    @media (max-width: 996px) {
      margin-bottom: 16px;
      font-size: 24px;
    }
    @media (max-width: 768px) {
      margin-left: 16px;
    }
  }

  &__container {
    padding: 0 16px;
    max-width: 1165px;

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  // .news__items
  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 32px;
    justify-content: space-between;

    @media (max-width: 996px) {
      gap: 8px;
    }
  }

  // .news__item
  &__item {
    display: flex;
    flex-direction: column-reverse;
    cursor: pointer;
    background: #FFF;
    flex: 0 1 calc(50% - 16px);
    overflow: hidden;
    padding: 4px;

    & > svg {
      border-radius: 9px;
    }

    @media (min-width: 768px) {
      border-radius: 24px;
    }
    @media (max-width: 996px) {
      flex: 0 1 calc(50% - 8px);
    }

    @media (max-width: 768px) {
      flex: 1 1 100%;
      flex-direction: column;
      padding: 12px 16px;
      gap: 8px;
    }

    &:hover {
      .news__image img {

        transform: scale(1.1);
      }
    }

    &:nth-child(3n + 1) {
      display: flex;
      flex-direction: row;
      gap: 32px;
      flex: 1 1 100%;
      padding: 4px;

      @media (min-width: 768px) {
        .news__info {
          flex: 1 1 52%;
        }
        &:nth-child(even) {
          flex-direction: row-reverse;

          .news__info {
            padding: 25px 0px 25px 32px;
          }
        }

      }

      .news__image {
        padding: 0;
        flex: 1 0 48%;
      }

      .news__description {
        -webkit-line-clamp: 6;
      }

      @media (min-width: 768px) {

        .news__info {
          padding: 25px 32px 25px 0;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 8px;
        padding: 12px 16px;
        .news__image {
          flex: 1 1 auto;
        }
        .news__description {
          -webkit-line-clamp: 3;
        }
      }

    }


  }

  // .news__image
  &__image {
    width: 100%;
    height: 269px;

    border-radius: 20px;
    overflow: hidden;

    @media (max-width: 768px) {
      height: 290px;
      border-radius: 9px;
    }
    @media (max-width: 576px) {
      height: 190px;
    }

    img {
      transition: all .3s ease 0s;
      width: 100%;
      object-fit: cover;
      border-radius: 20px;
      height: 100%;
      @media (max-width: 768px) {
        border-radius: 9px;
      }
    }
  }

  // .news__info
  &__info {
    display: flex;
    flex-direction: column;
    gap: 4px;


    @media (min-width: 768px) {
      padding: 15px 16px 8px 16px;
    }
  }

  // .news__date
  &__date {
    color: #6A6A6A;
    font-feature-settings: 'clig' off, 'liga' off;

    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;

    @media (max-width: 996px) {
      font-size: 13px;
      line-height: 1.2;
    }
  }

  // .news__title
  &__title {
    color: #2C2C2C;
    font-feature-settings: 'clig' off, 'liga' off;

    font-size: 24px;
    font-weight: 700;
    line-height: 1.3;
    @media (max-width: 996px) {
      font-size: 18px;
      line-height: 1.5;
    }
  }

  // .news__description
  &__description {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #6A6A6A;
    font-feature-settings: 'clig' off, 'liga' off;

    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;

    @media (max-width: 768px) {
      -webkit-line-clamp: 3;
    }
  }
}