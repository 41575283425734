.news-modal {

  // .news-modal__body
  &__body {
  }

  // .news-modal__content
  &__content {

    max-width: initial;

    @media (min-width: 996px) {
      width: 50%;
    }
    @media (max-width: 996px) {
      height: 91vh;
      width: 100%;
    }
  }

  // .news-modal__image
  &__image {
    height: 37%;

    @media (max-width: 996px) {
      height: 48vh;
    }
    @media (max-width: 576px) {
      height: 24.64vh;
    }
  }

  // .news-modal__date
  &__date {
    color: #6A6A6A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 4px;
    @media (max-width: 996px) {
      font-size: 13px;
      line-height: 1.2;
    }
  }

  // .news-modal__info
  &__info {
    padding: 16px 32px;

    @media (max-width: 996px) {
      padding: 20px 16px;
    }
  }

  // .news-modal__title
  &__title {
    margin-bottom: 20px;

    @media (max-width: 996px) {
      margin-bottom: 16px;
    }
  }

  // .news-modal__description
  &__description {
  }

  //.news-modal__close
  &__close {
    top: 20px;
  }
}