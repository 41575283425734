.home {
  &__inner {
    display: inline-flex;
    gap: 32px;

    @media (max-width: 996px) {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}