.map {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;

  & * {
    touch-action: auto
  }

  &__location {
    display: inline;
    white-space: nowrap;
    color: #10002B;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-weight: 400;

    line-height: 1.4;
    @media (min-width: 996px) {
      padding: 4px 12px;
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.70);
      margin-left: 24px;
    }
    @media (max-width: 996px) {
      display: none;
    }
  }

  [class $='-image-with-content'] {
    padding: 5px 5px 10px;
    background-repeat: no-repeat;

    &::after {
      content: '';
      position: absolute;
      top: -5px;
      left: -5px;
      background-image: url("../../assets/img/icons/mark-white.svg");
      filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.20));
      background-repeat: no-repeat;
      z-index: -10000;
      width: 24px;
      height: 29px;
    }
  }

  [class $='button-icon_icon_geolocation'] {
    background-image: url("../../assets/img/icons/location-map.svg");
    width: 24px;
    height: 24px;
    border: none;

  }

  .ymaps-2-1-79-zoom {
    padding: 40px 16px 45px 0;
  }

  [class  *= '-zoom__plus'], [class  *= '-zoom__minus'], [title="Определить ваше местоположение"] {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    width: 40px;
    height: 40px;

    & > [class $= '-float-button-icon_icon_geolocation'] {
      width: 100%;
    }

    & > ymaps {
      width: calc(100% - 10px);
    }
  }

  [class $= '-copyrights-pane'] {
    display: none;
  }

  [class *= 'islands_clusterSmallIcon'] {
    background-color: #fff;
    background-image: none;
    border-radius: 50%;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.20);

    [class $= '-svg-icon-content'] {
      display: flex;
      justify-content: center;
      align-items: center;
      inset: 0;

      ymaps {
        color: #2C2C2C;
        font-feature-settings: 'clig' off, 'liga' off !important;
        font-family: 'Roboto' !important;
        font-size: 18px !important;
        font-weight: 500 !important;
        line-height: 1.5 !important;
      }

    }
  }

  .ymaps-2-1-79-zoom__plus .ymaps-2-1-79-zoom__icon {
    background-image: url("../../assets/img/icons/plus.svg");

  }

  .ymaps-2-1-79-zoom__minus .ymaps-2-1-79-zoom__icon {
    background-image: url("../../assets/img/icons/minus.svg");
  }
}
