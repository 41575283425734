.home {
  // .home__cards
  &__cards {
    position: relative;
    @media (max-width: 996px) {
      padding-top: 16px;
      padding-right: 16px;

      &::after {
        content: "";
        position: absolute;
        left: -16px;
        top: 0;
        bottom: 0;
        right: 16px;
        background-color: #fff;
        z-index: -1;
        width: calc(100% + 32px);
      }
    }
  }

  // .home__title
  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.33;
    color: #2c2c2c;
    font-feature-settings: "clig" off, "liga" off;

    @media (max-width: 996px) {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 4px;
    }
  }

  // .home__description
  &__description {
    font-size: 14px;
    line-height: 1.4;
    color: #6a6a6a;
    font-feature-settings: "clig" off, "liga" off;
    margin-bottom: 24px;
    max-width: 392px;

    @media (max-width: 998px) {
      margin-bottom: 8px;
    }
  }

  // .home__items
  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 50px;
    justify-content: space-between;
    @media (min-width: 996px) {
      & > .home__item:nth-child(5n + 1),
      & .home__item:nth-child(5n + 2) {
        flex: 1 1 calc(50% - 16px);

        .place-card__image {
          width: 406px;
          height: 192px;
        }

        .place-card__title {
          font-size: 24px;
          line-height: 1.3;

          @media (max-width: 996px) {
            font-size: 18px;
            line-height: 1.5;
            color: #10002b;
          }
        }
      }
    }
  }

  // .home__item
  &__item {
    flex: 1 1 calc(33.33% - 16px);

    @media (max-width: 996px) {
      flex: 1 1 calc(50% - 16px);
    }

    @media (max-width: 576px) {
      flex: 1 1 100%;
    }

    .place-card__image {
      height: 128px;

      @media (max-width: 576px) {
        height: 176px;
      }
    }

    .place-card__title {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.5;
    }
  }
}

.title {
}

.place-card {
  cursor: pointer;
  overflow: hidden;
  &_n {
    pointer-events: none;
  }
  @media (min-width: 996px) {
    background-color: #fff;
    padding: 4px;
    border-radius: 24px;
  }

  &:hover {
    .place-card__image img {
      transform: scale(1.1);
    }
  }

  // .place-card__block-image
  &__block-image {
    position: relative;
    margin-bottom: 8px;
    min-height: 128px;
  }

  // .place-card__image
  &__image {
    position: relative;
    overflow: hidden;
    border-radius: 20px;

    @media (max-width: 996px) {
      border-radius: 12px;
    }

    img {
      width: 100%;
      transition: all 0.3s ease 0s;
      object-fit: cover;
      border-radius: 20px;
      height: 100%;
      @media (max-width: 996px) {
        border-radius: 12px;
      }
    }
  }

  &__cat-img {
    position: absolute;
    left: 10px;
    bottom: 10px;
    width: 50px !important;
    height: 50px !important;
  }

  // .place-card__bonus
  &__bonus {
    position: absolute;
    top: 18px;
    left: 20px;
    font-weight: 500;
    line-height: 1.5;
    padding: 2px 4px;
    font-feature-settings: "clig" off, "liga" off;
    color: #fff;
    border-radius: 8px;
    background-color: #ff3f38;

    @media (max-width: 996px) {
      font-size: 14px;
      top: 8px;
      left: 8px;
      line-height: 1.4;
    }
  }

  // .place-card__bonus_cash
  &__bonus_cash {
    background-color: #22be54;
  }

  &__bonus_point {
    background-color: #2b82e9;
    padding-left: 26px;

    &:after {
      content: "";
      position: absolute;
      left: 4px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 18px;
      height: 18px;
      background: url("../../assets/img/icons/points.svg") 0 0 / cover no-repeat;
    }
  }

  // .place-card__category
  &__category {
    color: #f46c1b;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px;
    line-height: 1.42;
    margin-bottom: 4px;

    @media (max-width: 996px) {
      font-size: 10px;
      margin-bottom: 0;
    }
  }

  &__category_cafe {
    color: #1899e9;
  }

  &__category_shop {
    color: #fc1c7a;
  }

  // .place-card__title
  &__title {
    overflow: hidden;
    color: #10002b;
    white-space: nowrap;
    font-feature-settings: "clig" off, "liga" off;
    text-overflow: ellipsis;
    font-weight: 500;

    @media (min-width: 996px) {
      margin-bottom: 6px;
    }
  }

  // .place-card__info
  &__info {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 4px;

    @media (min-width: 996px) {
      padding-left: 16px;
    }
    @media (max-width: 996px) {
      flex-direction: column-reverse;
    }
  }

  // .place-card__location
  &__location {
    color: #6a6a6a;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px;
    line-height: 1.4;

    @media (max-width: 996px) {
      position: absolute;
      top: -34px;
      left: 8px;
      color: #10002b;
      font-size: 12px;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.7);
      padding: 2px 4px;
    }
  }
}
