.search-modal {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  // pointer-events: none;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  transition: all 0.6s ease 0s;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;

  &_active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    left: 0;
    overflow-y: auto;

    .search-mobile_category {
      .category {
        display: none;
        padding: 12px 0 20px;
        gap: 16px;
        flex-direction: row;

        &.history {
          display: flex;
          gap: 0;
          padding: 8px 6px;
          height: initial;
        }

        &__title {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.5;
          color: #6a6a6a;
          max-width: 85%;

          strong {
            color: #2c2c2c;
            font-weight: 700;
          }
        }

        &__image {
          padding: 8px !important;

          img {
            width: 24px !important;
            height: 24px !important;
          }
        }
      }
    }

    .search-modal__container {
      transform: translateY(5%);
      border-radius: 16px 16px 0px 0px;

      .search-modal__body {
        padding: 16px;
      }

      .sidebar__search {
        display: block;
        border-radius: 12px;

        &::before {
          opacity: 0.4;
          top: initial;
          bottom: initial;
          margin: initial;
        }
      }

      .sidebar__search input {
        font-size: 18px;
        color: #2c2c2c;
        line-height: 1.5;

        &::placeholder {
          color: #9a9a9a;
        }
      }
    }
  }

  &__container {
    background-color: #fff;
    min-height: 100%;
    transition: all 0.6s ease 0s;
    margin-top: 10%;
    transform: translateY(200%);
  }
}
